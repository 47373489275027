import React from "react";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import classNames from "classnames";

import {useIsMobile} from "../../util/responsiveness";
import ProductPageContainer from "../../components/common/ProductPageContainer";
import ShadowEgg from "../../components/common/ShadowEgg";
import Columns from "../../components/common/Columns";
import ProductSpecificationsCard from "../../components/products/ProductSpecificationsCard";
import Egg from "../../components/common/Egg";
import H2WithSubtitle from "../../components/common/H2WithSubtitle";
import FigureGrid from "../../components/common/FigureGrid";
import FigureAlt from "../../components/common/FigureAlt";
import ChevronLink from "../../components/common/ChevronLink";

import * as classes from "./tps-1.module.scss";

export default function Tps1Page({data}) {

    const FIGURE_IMAGE_PROPS = {
        layout: "constrained",
        width: 564,
        height: 348
    };

    const isMobile = useIsMobile();

    return (
        <ProductPageContainer>

            <Helmet>
                <title>ТПС-1 – транспортная питательная среда</title>
            </Helmet>

            <div className={classes.contentWithMainEgg}>
                <ShadowEgg className={classes.mainEggContainer}>
                    <StaticImage
                        src="img/tps-1-1.jpg"
                        alt="Внесение цитологического материала в пробирку с транспортной средой путем прокалывания крышки"
                        layout="constrained"
                        width={506}
                        height={391} />
                </ShadowEgg>
                <h1>Транспортная питательная среда ТПС-1</h1>
                <p>Жидкостная цитология — инновационная альтернатива традиционному цитоисследованию. Сегодня она признана наиболее информативным способом получения биологического материала и рекомендована в качестве «золотого стандарта» диагностики интраэпителиальных неоплазий слизистой цервикального канала и влагалищной части шейки матки.</p>
            </div>

            <h2>Преимущества метода:</h2>
            <ul>
                <li>снижение процента потери клеточного материала;</li>
                <li>длительный срок хранения биологического материала;</li>
                <li>снижение ошибок преаналитического этапа (стандартизация приготовления и окрашивания цитологических мазков);</li>
                <li>повышение качества цитологического препарата;</li>
                <li>возможность повторного исследования и/или применения дополнительных методик.</li>
            </ul>

            <h2>Сравнение традиционной и жидкостной цитологии</h2>
            <table>
                <thead>
                    <tr>
                        <th>Параметр</th>
                        <th>Традиционная цитология</th>
                        <th>Жидкостная цитология</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Фоновые элементы</th>
                        <td>Обильное количество</td>
                        <td>Минимальное количество</td>
                    </tr>
                    <tr>
                        <th>Распределение материала на стекле</th>
                        <td>Неравномерное</td>
                        <td>Равномерное</td>
                    </tr>
                    <tr>
                        <th>Окрашивание</th>
                        <td>Неравномерное</td>
                        <td>Равномерное</td>
                    </tr>
                    <tr>
                        <th>Сохранность материала</th>
                        <td>Появление артефактов при нарушении правил транспортировки</td>
                        <td>Полная</td>
                    </tr>
                    <tr>
                        <th>Качество препарата</th>
                        <td>Часто неадекватное</td>
                        <td>Стандартизованное</td>
                    </tr>
                    <tr>
                        <th>Возможность дополнительных методик</th>
                        <td>Ограниченно, трудоемко</td>
                        <td>Возможно (ПЦР, ИЦХ, cell‑block)</td>
                    </tr>
                </tbody>
            </table>

            <div className={classes.contentWithSpecificationsCard}>
                <ProductSpecificationsCard className={classes.specificationsCard}>
                    <ProductSpecificationsCard.Entry
                        label="Регистрационное удостоверение"
                        value={
                            <ChevronLink
                                use="a"
                                href={data["registrationCertificateFile"]["publicURL"]}
                                target="_blank">РЗН 2019/9512 от 14.07.2020 г.</ChevronLink>} />
                    <ProductSpecificationsCard.Entry
                        label="Патент на изобретение"
                        value={
                            <ChevronLink
                                use="a"
                                href={data["patentFile"]["publicURL"]}
                                target="_blank">№ 2710226</ChevronLink>} />
                    <ProductSpecificationsCard.Entry
                        value={
                            <ChevronLink
                                use="a"
                                href={data["instructionsFile"]["publicURL"]}
                                target="_blank">Инструкция</ChevronLink>} />
                    <ProductSpecificationsCard.Entry
                        label="Срок годности"
                        value="12 месяцев" />
                    <ProductSpecificationsCard.Entry
                        label="Упаковка"
                        value="100 штук" />
                    <ProductSpecificationsCard.Entry
                        label="Объем"
                        value="1,5 мл, 5,0 мл, 10,0 мл, 20,0 мл" />
                    <ProductSpecificationsCard.Entry
                        label="Условия хранения"
                        value="Температура +4/+8 °С, отсутствие прямого солнечного света" />
                </ProductSpecificationsCard>
                <p>Существенные недостатки метода связаны с расходными материалами, а именно с представленными на рынке транспортными средами. Во-первых, влияние консервантов транспортной среды приводит к изменению морфологии клеток. Во-вторых, из-за дегидратации клеток компонентами среды и уплотнения клеточных структур возникает привязанность к методу окраски.</p>
                <p>Транспортная питательная среда ТПС-1 решает эти проблемы: она минимизирует агрессивное влияние на клетки благодаря отсутствию спиртовых консервантов и сохраняет морфологию клеток аналогичной традиционной цитологии, а её инновационный состав позволяет использовать различные методы окрашивания препаратов.</p>
                <h2>Преимущества ТПС-1:</h2>
                <ul>
                    <li>сохранение морфологии клеток до 7 дней при комнатной температуре;</li>
                    <li>совместима с любым типом материала (соскобы, материал ТИАБ различных локализаций);</li>
                    <li>совместима со всеми видами окраски (Романовского, Папенгейма, Лейшмана, Папаниколау, Май-Грюнвальд Гимзе, и др.);</li>
                    <li>возможность дополнительных исследований (ИЦХ, ФИЦХ, ПЦР, NGS, cell-block).</li>
                </ul>
            </div>

            <h2>Варианты фасовки ТПС-1</h2>
            <Columns className={classes.columns}>
                <Columns.Column className={classes.column}>
                    <h3>1,5 мл</h3>
                    <p>Пункция мягкотканных образований — молочная и щитовидная железы, печень, лимфоузлы.</p>
                    <ShadowEgg className={classNames(classes.columnEgg, classes.columnEgg1Container)} flipped={isMobile}>
                        <StaticImage
                            src="img/tps-1-2.jpg"
                            alt="Вариант фасовки ТПС-1 – 1,5 мл"
                            layout="constrained"
                            width={360}
                            height={278} />
                    </ShadowEgg>
                </Columns.Column>
                <Columns.Column className={classes.column}>
                    <h3>5,0 мл/10,0 мл</h3>
                    <p>Пункция мягкотканных образований, соскобы с поверхностных опухолей в том числе шейки матки при заборе биоматериала урогенитальным зондом.</p>
                    <Egg className={classes.columnEgg} flipped={!isMobile}>
                        <StaticImage
                            src="img/tps-1-3.jpg"
                            alt="Вариант фасовки ТПС-1 – 5,0 мл/10,0 мл"
                            layout="constrained"
                            width={360}
                            height={278} />
                    </Egg>
                </Columns.Column>
                <Columns.Column className={classes.column}>
                    <h3>20,0 мл</h3>
                    <p>Соскобы и мазки с поверхностных образований. Скрининг рака шейки матки. Комплиментарна любым щеткам.</p>
                    <Egg className={classes.columnEgg} flipped={isMobile}>
                        <StaticImage
                            src="img/tps-1-4.jpg"
                            alt="Вариант фасовки ТПС-1 – 20,0 мл"
                            layout="constrained"
                            width={360}
                            height={278} />
                    </Egg>
                </Columns.Column>
            </Columns>

            <H2WithSubtitle subtitle="фасовка 1,5 мл.">Пример использования при пунктировании</H2WithSubtitle>
            <Columns>
                <Columns.Column className={classes.column}>
                    <h3>Первый шаг</h3>
                    <p>Получение материала при тонкоигольной аспирационной биопсии.</p>
                    <Egg className={classes.columnEgg} flipped={!isMobile}>
                        <StaticImage
                            src="img/tps-1-5.jpg"
                            alt="Получение материала при тонкоигольной аспирационной биопсии."
                            layout="constrained"
                            width={360}
                            height={278} />
                    </Egg>
                </Columns.Column>
                <Columns.Column className={classes.column}>
                    <h3>Второй шаг</h3>
                    <p>Внесение материала в среду ТПС-1.</p>
                    <ol className={classes.smallList}>
                        <li>Внесение материала путем прокалывания крышки пробирки. Исключает любую возможную контаминацию.</li>
                        <li>Аспирация раствора ТПС-1 для промывания иглы. Существенно увеличивает процент клеточного материала.</li>
                        <li>Окончательное внесение материала.</li>
                    </ol>
                    <Egg className={classes.columnEgg} flipped={isMobile}>
                        <StaticImage
                            src="img/tps-1-6.jpg"
                            alt="Внесение материала в среду ТПС-1."
                            layout="constrained"
                            width={360}
                            height={278} />
                    </Egg>
                </Columns.Column>
                <Columns.Column className={classes.column}>
                    <h3>Третий шаг</h3>
                    <p>Приготовление цитологических препаратов, проведение дополнительных исследований (ИЦХ, ФИЦХ, ПЦР, NGS, cell-block).</p>
                    <Egg className={classes.columnEgg} flipped={!isMobile}>
                        <StaticImage
                            src="img/tps-1-7.jpg"
                            alt="Приготовление цитологических препаратов, проведение дополнительных исследований (ИЦХ, ФИЦХ, ПЦР, NGS, cell-block)."
                            layout="constrained"
                            width={360}
                            height={278} />
                    </Egg>
                </Columns.Column>
            </Columns>

            <h2>Интраоперационный соскоб с легкого</h2>
            <FigureGrid>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-1.jpg"
                        alt="Интраоперационный соскоб с легкого до ТПС-1"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>До ТПС-1</figcaption>
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-2.jpg"
                        alt="Интраоперационный соскоб с легкого, ТПС-1, 3 день"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ТПС-1 <em>(3 день)</em></figcaption>
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-3.jpg"
                        alt="Интраоперационный соскоб с легкого, ТПС-1, 5 день"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ТПС-1 <em>(5 день)</em></figcaption>
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-4.jpg"
                        alt="Интраоперационный соскоб с легкого, ТПС-1, 7 день"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ТПС-1 <em>(7 день)</em></figcaption>
                </FigureAlt>
            </FigureGrid>

            <H2WithSubtitle subtitle="5 день в ТПС-1">Интраоперационный соскоб с легкого</H2WithSubtitle>
            <FigureGrid>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-5.jpg"
                        alt="Интраоперационный соскоб с легкого, 5 день в ТПС-1, cell-block"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>Cell-block</figcaption>
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-6.jpg"
                        alt="Интраоперационный соскоб с легкого, 5 день в ТПС-1, ФИЦХ (СК7), AlexaFluor-488"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ФИЦХ <em>(СК7), AlexaFluor-488</em></figcaption>
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-7.jpg"
                        alt="Интраоперационный соскоб с легкого, 5 день в ТПС-1, ИЦХ (СК7)"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ИЦХ <em>(СК7)</em></figcaption>
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-8.jpg"
                        alt="Интраоперационный соскоб с легкого, 5 день в ТПС-1, ФИЦХ (EpCAM/BerEP4), AlexaFluor-488"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ФИЦХ <em>(EpCAM/BerEP4), AlexaFluor-488</em></figcaption>
                </FigureAlt>
            </FigureGrid>

            <h2>Осадок асцитической жидкости</h2>
            <FigureGrid>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-9.jpg"
                        alt="Осадок асцитической жидкости до ТПС-1"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>До ТПС-1</figcaption>
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-10.jpg"
                        alt="Осадок асцитической жидкости, ТПС-1, 3 день"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ТПС-1 <em>(3 день)</em></figcaption>
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-11.jpg"
                        alt="Осадок асцитической жидкости, ТПС-1, 9 день"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ТПС-1 <em>(9 день)</em></figcaption>
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-12.jpg"
                        alt="Осадок асцитической жидкости, ИЦХ (WT1)"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ИЦХ <em>(WT1)</em></figcaption>
                </FigureAlt>
            </FigureGrid>

            <H2WithSubtitle subtitle="7 день в ТПС-1">Осадок асцитической жидкости</H2WithSubtitle>
            <FigureGrid>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-13.jpg"
                        alt="Осадок асцитической жидкости, ИЦХ (CA125)"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ИЦХ <em>(CA125)</em></figcaption>
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-14.jpg"
                        alt="Осадок асцитической жидкости, ФИЦХ (СА125), AlexaFluor-488"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ФИЦХ <em>(СА125), AlexaFluor-488</em></figcaption>
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-15.jpg"
                        alt="Осадок асцитической жидкости, ИЦХ (СR7)"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ИЦХ <em>(СR7)</em></figcaption>
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-1-figure-16.jpg"
                        alt="Осадок асцитической жидкости, ФИЦХ (EpCAM/BerEP4), AlexaFluor-488"
                        {...FIGURE_IMAGE_PROPS} />
                    <figcaption>ФИЦХ <em>(EpCAM/BerEP4), AlexaFluor-488</em></figcaption>
                </FigureAlt>
            </FigureGrid>

        </ProductPageContainer>
    );

}

export const pageQuery = graphql`
    query {
        registrationCertificateFile: file(relativePath: {eq: "ru-rzn-2019-9512-ot-14-07-2020.pdf"}) {
            publicURL
        }
        patentFile: file(relativePath: {eq: "patent-2710226-tps.pdf"}) {
            publicURL
        }
        instructionsFile: file(relativePath: {eq: "instruktsiia-tps-1.pdf"}) {
            publicURL
        }
    }  
`;
