import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./H2WithSubtitle.module.scss";

export default function H2WithSubtitle({className, subtitle, children}) {
    return (
        <div className={classNames(classes.container, className)}>
            <h2>{children}</h2>
            <p className={classes.subtitle}>{subtitle}</p>
        </div>
    );
}

H2WithSubtitle.propTypes = {
    className: PropTypes.string,
    subtitle: PropTypes.string.isRequired
};
