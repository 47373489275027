import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./Columns.module.scss";

function Columns({className, ...props}) {
    return (
        <div
            className={classNames(classes.container, className)}
            {...props} />
    );
}

Columns.propTypes = {
    className: PropTypes.string
};

function Column({className, ...props}) {
    return (
        <div
            className={classNames(classes.column, className)}
            {...props} />
    );
}

Column.propTypes = {
    className: PropTypes.string
};

Columns.Column = Column;

export default Columns;
